<template>
  <div>
    <div class="digital">
      <div class="banner">
        <div class="head">
          <div class="title">企业数字化咨询</div>
          <div class="btn" @click="goAbout">方案咨询</div>
        </div>
      </div>
      <div class="digitalMain">
        <div class="title"></div>
        <div class="boxes">
          <!-- <Box
            v-for="(item, index) in boxData"
            :key="index"
            :title="item.title"
            :content="item.content"
            :imgUrl="item.imgUrl"
          ></Box> -->
          <Hexagon
            class="hexagon-top hexagon"
            content="数字化商业模式及组织设计"
            description="包括数字化商业模式设计和数字化组织调整与人才发展设计"
            :imgUrl="require('@/assets/main/digital/digital-1.png')"
          ></Hexagon>
          <Hexagon
            class="hexagon-topLeft hexagon"
            content="内部运营流程整合优化"
            description="包括面向客户的流程重构，面向企业内部的运营流程的整合优化"
            :imgUrl="require('@/assets/main/digital/digital-2.png')"
          ></Hexagon>
          <Hexagon
            class="hexagon-topRight hexagon"
            content="数字化转型蓝图规划"
            description="包括数字化转型蓝图规划、数字化转型策略与计划、数字化组织与人才规划等"
            :imgUrl="require('@/assets/main/digital/digital-3.png')"
          ></Hexagon>
          <Hexagon
            class="hexagon-bottom hexagon"
            content="数字化转型专项场景落地服务"
            description="包括筛选数字化转型场景、关键数字化转型场景定义、试点数字化场景可行分析、场景优先级排序等"
            :imgUrl="require('@/assets/main/digital/digital-4.png')"
          ></Hexagon>
          <Hexagon
            class="hexagon-bottomLeft hexagon"
            content="数字化转型变革与项目管理"
            description="包括数字化项目实施、评价与调整、数字化项目管理与变革管理三个方面"
            :imgUrl="require('@/assets/main/digital/digital-5.png')"
          ></Hexagon>
          <Hexagon
            class="hexagon-bottomRight hexagon"
            content="数字化转型战略设计"
            description="包含数字化机会扫描、数字化转型现状评估、客户旅程诊断与机会识别、数字化发展战略研讨会、数字化转型战略建议等 5 个方面"
            :imgUrl="require('@/assets/main/digital/digital-6.png')"
          ></Hexagon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Box from "@/components/box";
import Hexagon from "@/components/hexagon";
export default {
  components: { Box, Hexagon },
  data() {
    return {
      boxData: [
        {
          imgUrl: require("@/assets/main/digitalChange/1.png"),
          title: "数字化转型战略设计",
          content:
            "包含数字化机会扫描、数字化转型现状评估、客户旅程诊断与机会识别、数字化发展战略研讨会、数字化转型战略建议等 5 个方面",
        },
        {
          imgUrl: require("@/assets/main/digitalChange/2.png"),
          title: "数字化商业模式及组织设计",
          content: "包括数字化商业模式设计和数字化组织调整与人才发展设计",
        },
        {
          imgUrl: require("@/assets/main/digitalChange/3.png"),
          title: "内部运营流程整合优化",
          content: "包括面向客户的流程重构，面向企业内部的运营流程的整合优化",
        },
        {
          imgUrl: require("@/assets/main/digitalChange/4.png"),
          title: "数字化转型蓝图规划",
          content:
            "包括数字化转型蓝图规划、数字化转型策略与计划、数字化组织与人才规划等",
        },
        {
          imgUrl: require("@/assets/main/digitalChange/5.png"),
          title: "数字化转型专项场景落地服务",
          content:
            "包括筛选数字化转型场景、关键数字化转型场景定义、试点数字化场景可行分析、场景优先级排序等",
        },
        {
          imgUrl: require("@/assets/main/digitalChange/6.png"),
          title: "数字化转型变革与项目管理",
          content:
            "包括数字化项目实施、评价与调整、数字化项目管理与变革管理三个方面",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    goAbout() {
      this.$router.push({ path: "/about", query: { ask: "true" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.digital {
  /* width: 100vw; */
  height: 1550px;
  .banner {
    height: 400px;
    background: url("../../assets/main/digitalChange/banner.png") no-repeat;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    .head {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .title {
        width: 288px;
        height: 32px;
        line-height: 22px;
        font-size: 32px;
        font-weight: bold;
        color: rgba(234, 144, 82, 1);
        margin: 0 auto;
        margin-top: 144px;
        margin-bottom: 40px;
        text-align: center;
      }
      .btn {
        width: 134px;
        height: 40px;
        background: rgba(234, 144, 82, 1);
        border-radius: 6px;
        text-align: center;
        line-height: 40px;
        font-size: 11px;
        color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        cursor: pointer;
      }
      .btn:hover {
        background: rgba(245, 166, 117, 1);
      }
    }
  }
  .digitalMain {
    width: 1200px;
    height: 1100px;
    margin: 0 auto;
    overflow: hidden;
    .title {
      width: 272px;
      height: 41px;
      margin: 0 auto;
      margin-top: 40px;
      background: url("../../assets/main/digital/Functional-tittle.png")
        no-repeat;
    }
    .boxes {
      width: 893px;
      height: 930px;
      // display: flex;
      // justify-content: space-between;
      // flex-wrap: wrap;
      background: url("../../assets/main/digital/background.png");
      background-size: cover;
      background-position: -400px;
      margin: 0 auto;
      margin-top: 43px;
      position: relative;
      .hexagon {
        position: absolute;
      }
      .hexagon-top {
        top: -200px;
        left: 362px;
      }
      .hexagon-topLeft {
        left: 91px;
        top: -45px;
      }
      .hexagon-topRight {
        right: 81px;
        top: -45px;
      }
      .hexagon-bottomLeft {
        left: 91px;
        top: 267px;
      }
      .hexagon-bottomRight {
        right: 80px;
        top: 267px;
      }
      .hexagon-bottom {
        bottom: -200px;
        left: 362px;
      }
    }
  }
}
</style>
