<template>
  <div class="hexagon" @mouseenter="onMouseover" @mouseleave="onMouseLeave">
    <div class="hexagon__item hexagon__item_left"></div>
    <div class="hexagon__item hexagon__item_center"></div>
    <div class="hexagon__item hexagon__item_right"></div>
    <div class="desc" v-if="flag">
      <img :src="imgUrl" alt="" />
      <div class="content">{{ content }}</div>
    </div>
    <div class="descHover" v-else>
      <div class="contentHover">{{ content }}</div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["imgUrl", "content", "description"],
  data() {
    return {
      flag: true,
    };
  },
  methods: {
    onMouseover() {
      this.flag = false;
    },
    onMouseLeave() {
      this.flag = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hexagon {
  width: 179px;
  height: 310px;
  position: relative;
  margin: 200px auto;
  cursor: pointer;
}
.hexagon:hover {
  .hexagon__item {
    background: #ea9052;
  }
}
.hexagon__item {
  width: 100%;
  height: 100%;
  border: 1px solid #ea9052;

  position: absolute;
  border-left: none;
  border-right: none;
  top: 0;
  left: 0;
}

.hexagon__item_left {
  transform: rotate(-60deg);
}
.hexagon__item_center {
}
.hexagon__item_right {
  transform: rotate(60deg);
}
.desc {
  width: 208px;
  height: 205px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  img {
    display: block;
    width: 179px;
    height: 179px;
  }
  .content {
    width: 208px;
    height: 16px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }
}
.descHover {
  width: 224px;
  height: 98px;
  position: absolute;
  top: 0;
  left: -10px;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  .contentHover {
    color: #ffffff;
    height: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  .description {
    width: 224px;
    min-height: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
    opacity: 1;
    margin-top: 20px;
  }
}
</style>
